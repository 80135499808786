$primary:       #aa2585;
$secondary:     #a4c73c;
$tertiery:      #3c4126;

.yellow{
  color: #ffdd00;
}

a{
  text-decoration:none;
  color:$dark;
  &:link,&:visited{
    text-decoration:none;
    color:$dark;
  }
  &:active,&:hover{
    text-decoration:none;
    color:$primary;
  }
}

h1,h2,h3{
  padding-top:30px;
  padding-bottom:30px;
}



body {
  overflow-x: hidden;
  margin:0;
  background-color: #fff;
  background: url(../../img/bg-i.jpg) no-repeat center top;
  background-size:100%;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#side-wrapper {
  z-index: 99999;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: $dark;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #side-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 0;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

#menu-toggle{
  position: fixed;
  top:25px;
  left:-14px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  z-index:99998;
  &.toggled{
    left: 236px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  a{
    text-decoration:none;
    &:link,&:visited,&:active,&:hover{
      color:$light;
    }
  }
}

#page-content-wrapper{
  background-color: #fff;
  background: url(../../img/bg-i-2.jpg) no-repeat center bottom;
  background-size:100%;
}

h1{
  &.title{
    padding-top:60px;
  }
}

/* Sidebar Styles */

.side-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.side-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.side-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.side-nav li a:active, .side-nav li a:focus {
  text-decoration: none;
}

.side-nav>.side-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
  font-family: 'munki-boy';
  font-size: 1.2rem;
  font-weight: 500;
}

.side-nav>.side-brand a {
  color: #999999;
}

.side-nav>.side-brand a:hover {
  color: #fff;
  background: none;
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 250px;
  }
  #side-wrapper {
    width: 0;
  }
  #wrapper.toggled #side-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    padding: 0;
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.contact-form{
  margin-bottom: 60px;
}

// page

body{
  height: 100%;
  background-color: #fff;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
  color: $dark;
}


.header{
  background-color: transparent;
  padding-top:80px;
  padding-bottom:80px;
  min-height:300px;
  position:relative;
}

.top-bar{
  background-color:#fff;
  min-height:60px;
  color:$secondary;
  padding-top:20px;
  padding-bottom:20px;
  border-bottom:3px solid $primary;
  .logo{
    font-family: 'munki-boy';
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0;
  }
  a{
    color:$secondary;
    &:link{
      color:$secondary;
    }
    &:hover,&:active,&:focus{
      color:#000;
    }
  }
}

.container{
  padding-top:60px;
  padding-bottom:60px;
}

.nav-pills{
  .nav-item{
    margin-bottom:30px;
    .nav-link{
      &:link,&:focus,&:visited{
        color:#fff;
        background-color:$primary;
      }
      &:active,&:hover{
        color:#fff;
        background-color:$secondary;
      }
    }
  }

    .nav-item.active .nav-link{
      color:#fff;
      background-color:$secondary;
      &:visited{
        color:#fff;
        background-color:$secondary;       
      } 
    }
}




//navbar

.nav-bar{
  background-color:#fff;
  min-height:60px;
  color:$dark;
  padding-top:20px;
  padding-bottom:20px;
  border-top:1px solid $primary;
  border-bottom:1px solid $primary;
  margin-bottom:30px;
  .nav-item{
    a{
      color:$dark;
      &:link{
        color:$dark;
      }
      &:hover,&:active,&:focus{
        color:$primary;
      }
    } 
    &.active{
      a{
        color:$primary;
        &:link{
          color:$primary;
        }
        &:hover,&:active,&:focus{
          color:$dark;
        }
      }       
    } 
  }
}

.navbar{
  background:#fff;
}

.navbar-brand{
  img{
    height:50px;
  }
}

.navbar-light .navbar-toggler-icon{
  width:auto;
  padding-top:5px;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
  color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-toggler {
  border-color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-brand, .navbar-light .navbar-toggler {
  color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover, .navbar-light .navbar-toggler:focus, .navbar-light .navbar-toggler:hover {
  color: rgba(228, 228, 228, 1);
}

.navbar-light .navbar-toggler-icon {
  background-image: none;
}

.bnav{
  padding-top:60px;
}

.main-heading{
  margin-bottom:120px;
}

// image-rendering

.image-size{
  width: 100%;
}




// sections


.carousel{
  border-bottom:none;
  margin-bottom:60px;
  &.text-carousel{
    background-color:$primary;
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev{
        display:block;
        padding-top:60px;
        padding-bottom:60px;
        height:300px;
        @include media-breakpoint-only(sm) {
          height:300px;
        }
        @include media-breakpoint-only(md) {
          height:300px;
        }
        @include media-breakpoint-only(lg) {
          height:200px;
        }
        @include media-breakpoint-only(xl) {
          height:200px;
        }
    }
  }
}



.blockquote{
  margin-left:-15px;
  padding-left:13px;
  border-left:2px solid $secondary;
}


.home-featured{
  .home-featured-icon{
    font-size:2.5em;
    float:left;
    margin-right:10px;
  }
  p{
    padding-top:15px;
  }
}

.img-fluid{
  max-width:auto;
  width:100%;
}

.jumbotron{
  border-radius:0;
  margin-top:4px;
  &.background{
    background-color:$dark;
    color:#fff;
    border-bottom:4px solid $secondary;
  }
  .display-4{
    font-size:4em;
    font-weight:bold;
    font-size:2em;
    @include media-breakpoint-only(sm) {
      font-size:2em;
    }
    @include media-breakpoint-only(md) {
      font-size:2em;
    }
    @include media-breakpoint-only(lg) {
      font-size:4em;
    }
    @include media-breakpoint-only(xl) {
      font-size:4em;
    }
  }
}

.creds{
  margin-bottom:30px;
}


.dark{
  background-color:$dark;
  border-top:2px solid #000;
  color:#fff;
  padding-top:60px;
  padding-bottom:60px;
}



.card-columns {
  column-count:1;
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

.alternate-block{
  background-color:$secondary;
  border-top:2px solid #ffffff;
  padding-bottom:40px;
  padding-top:60px;

}

.card{
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border:none;
  margin-bottom: 1.3rem;
  width:100%;
  background-color:$tertiery;
  color:$light;
  border-radius:4px;
  border:1px solid $light;
  &.purple{
    background-color:$primary;
    color:$light;
    border-radius:0;
    .card-header{
      border-bottom:1px solid $secondary;
    }
    a{
      color:$light;
      &:hover{
        color:#fff;
      }
      &:link,&:active,&:visited{
        color:$light;
      }
    }
    a{
      .more{
        font-weight:bold;
      }
    }
    p{
      font-size:0.8rem;
      padding-bottom:20px;
    }
    h4,h2{
      margin-top:0;
      margin-bottom:0;
      padding-top:10px;
      padding-bottom:10px;
    }
    h2{
      font-size:1rem;
    }
  }
  &.light{
    background-color:$light;
    color:$dark;
    border-radius:0;
    .card-header{
      border-bottom:2px solid $secondary;
      background-color:$tertiery;
      color:#fff;
    }
    a{
      color:$dark;
      &:hover{
        color:$primary;
      }
      &:link,&:active,&:visited{
        color:$dark;
      }
    }
    a{
      .more{
        font-weight:bold;
      }
    }
    p{
      font-size:0.8rem;
      padding-bottom:20px;
    }
    h4,h2{
      margin-top:0;
      margin-bottom:0;
      padding-top:10px;
      padding-bottom:10px;
    }
    h2{
      font-size:1rem;
    }
  }
  p{
    margin-bottom:0;
  }
  a{
    color:#fff;
    &:link,&:hover,&:active,&:visited{
      color:#fff;
    }
  }
  ul{
    padding-top:30px;
  }
  &.pad-top{
    margin-top:60px;
  }
  &.edged{
    border-left:4px solid $primary;
  }
  &.news{
    p{
      font-size:0.8rem;
      padding-bottom:20px;
    }
    h4,h2{
      margin-bottom:0;
      padding-bottom:10px;
    }
    h2{
      font-size:1rem;
    }
    a{
      .more{
        font-weight:bold;
      }
    }
  }
}

.home-links{
}



/* pagination
=================================*/

.pagin{
  margin-top: 60px;
}

.pro-tags{
  margin-top: 60px;
  margin-bottom: 60px;
}


/* footer
=================================*/

.footer{
    margin-top:300px;
  .row{
    color: $dark;
    padding-top: 30px;
    padding-bottom: 60px;
    h1{
      padding-bottom:0;
    }
    h2{
      padding-top:0;
    }
    &.pad-in{
      padding-left: 30px;
      padding-right: 30px;
      margin-left: -15px;
      margin-right: -15px;
    }
    a{
      color:$dark;
      &:link,&:visited{
        color:#000;
      }
      &:hover,&:active{
        color:$primary;
      }
    }
    ul{
      padding-left:0;      
      li{
        list-style-type:none;
      }
    }
    .foot-logo{
      margin-bottom:30px;
    }
    .nav-link{
      padding-top:0;
    }
  }
}

.bottom-footer{
  background-color: #d1d1d1;
  padding-top: 20px;
  padding-bottom: 10px;
  color:$light;
  a{
    color:$light;
    &:link,&:active,&:focus,&:hover{
      color:$light;
    }
  }
}


.text-area{
  color: $dark;
  margin-bottom: 30px;
}


.red{
  color:#ff0000;
}

.form-control{
  margin-bottom: 10px;
}

.client-logo{
  padding-left: 40px;
  padding-right: 40px;
}

iframe{

  &.map, &.youtube{
    border:none;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;

  }

}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 

  &.home{
    margin-top: 30px;
    margin-bottom: 30px;
  }

} 
.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

.btn-primary{
   background-color:$primary;
   border-color: darken($primary, 15);
   color:#fff;
   &:hover,&:active,&focus{
    background-color:darken($primary, 15);
   }
   &:focus,&.focus,&:active{
    box-shadow: 2px $primary;
   }
}

.menubtn.btn{
  border-radius: $border-radius $border-radius 0 0;
}


.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover {
  color: #fff;
  background-color: darken($primary, 15);
  border-color: darken($primary, 15);
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem darken($primary, 15);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: darken($primary, 15);
  border-color: darken($primary, 15);
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem darken($primary, 15);
}